
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPageBlogPostLayout} from 'layouts/CmsPageBlogPostLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPageBlogPostLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Ciao! Sono Emanuele e ho deciso di scrivere un blog.`}</p>
    <p>{`Nella vita faccio il web designer e lavoro come libero professionista. Ogni tanto scriverò post correlati al mio lavoro così da aiutarti se sei arrivato qui cercando informazioni utili.`}</p>
    <p>{`Sono anche uno spirito libero che ama le avventure e gli sport all’aria aperta. Questo blog sarà il mio diario dove raccoglierò storie sui miei giri in bici, le mie escursioni in montagna, i miei viaggi…`}</p>
    <p>{`Condividerò così un po’ di ciò che mi appassiona per farmi conoscere meglio. Spero che troverai anche qualche spunto fra queste mie pagine.`}</p>
    <p><a parentName="p" {...{
        "href": "contact"
      }}>{`Non esitare a scrivermi`}</a>{` se avrai dei suggerimenti che mi aiutino a migliorare o se troverai degli errori in questo sito. Il tuo contributo è importante!`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    